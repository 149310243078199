/*.animate {*/
/*    opacity: 0;*/
/*    transform: translateX(-50px);*/
/*    transition: all 0.5s ease;*/


/*}*/

.animate.show {
    opacity: 1;
    transform: translateY(0);
}

.animate.left-animate {
    opacity: 0;
    transform: translateX(-50px);
    transition: all 0.6s ease;
}

.animate.show.left-animate {
    opacity: 1;
    transform: translateY(0);
}

.animate.right-animate {
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.6s ease;
}

.animate.show.right-animate {
    opacity: 1;
    transform: translateY(0);
}

.animate.top-animate {
    opacity: 0;
    transform: translateY(-50px);
    transition: all 0.6s ease;
}

.animate.show.top-animate {
    opacity: 1;
    transform: translateY(0);
}

.animate.bottom-animate {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.8s ease;
}

.animate.show.bottom-animate {
    opacity: 1;
    transform: translateY(0);
}

.animate.scale-animate {
    //opacity: 0;
    transform: scale(0);
    transition: all 0.8s ease;
}

.animate.show.scale-animate {
    //opacity: 1;
    transform: scale(1);
}